import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
// import { TailSpin } from 'react-loader-spinner';
import { Breadcrumb, Drawer, Spin, Steps } from 'antd';
import { FaLinkedin, FaFacebook, FaTwitter } from 'react-icons/fa';
import styled from "styled-components";
import {
  ApartmentOutlined,
  AppstoreOutlined,
  CalendarOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  DollarOutlined,
  FileSearchOutlined,
  MailOutlined,
  RobotOutlined,
  RocketOutlined,
  SettingOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined
} from "@ant-design/icons";
import { SideMenu } from '../components/Common/SideMenu';
import {Button, Container, Spinner} from "react-bootstrap";
import { useSelector } from 'react-redux';

// Styled components
const PageBackground = styled.div`
  background-color: #f4f4f4;
  min-height: 100vh;
  padding: 20px 20px;
`;
const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  // margin-bottom: 30px;
  transition: all 0.3s ease;

  // &:hover {
  //   box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  // }
`;

function EmailDisplay() {
  const location = useLocation();
  const navigate = useNavigate();
  const { personEmail, documentId, user_id, showHeader } = location.state;
  const [loading, setLoading] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailContent, setEmailContent] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [personName, setPersonName] = useState('');
  const [personFacebook, setPersonFacebook] = useState('');
  const [personTwitter, setPersonTwitter] = useState('');
  const [personLinkedin, setPersonLinkedin] = useState('');
  const [continueBtnLoading, setContinueBtnLoading] = useState(false);
  const { userId, basicInfo: basicInfoState } = useSelector(state => state.app);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const generateEmail = async () => {
      setLoading(true);
      try {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/integration/generate_custom_email', {
          person_email: personEmail,
          document_id: documentId,
          user_id: user_id
        });
        setEmailSubject(response.data.subject);
        setEmailContent(response.data.email_body);
        setCompanyDescription(response.data.company_description);
        setPersonName(response.data.person_name);
        setPersonFacebook(response.data.person_facebook || '');
        setPersonTwitter(response.data.person_twitter || '');
        setPersonLinkedin(response.data.person_linkedin || '')
      } catch (error) {
        console.error('Error generating email:', error);
      } finally {
        setLoading(false);
      }
    };

    generateEmail();
  }, []);

  const renderEmailContent = (content) => {
    return content.split('\n\n').map((paragraph, index) => (
      <p key={index} dangerouslySetInnerHTML={{ __html: paragraph.replace(/\n/g, '<br>') }} />
    ));
  };

  const renderSocialIcons = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {personLinkedin && (
          <a href={personLinkedin} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
            <FaLinkedin style={{ color: '#0077b5' }} />
          </a>
        )}
        {personFacebook && (
          <a href={personFacebook} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
            <FaFacebook style={{ color: '#3b5998' }} />
          </a>
        )}
        {personTwitter && (
          <a href={personTwitter} target="_blank" rel="noopener noreferrer">
            <FaTwitter style={{ color: '#1da1f2' }} />
          </a>
        )}
      </div>
    );
  };

  const handleContinue = async () => {
    setContinueBtnLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/update_review_samples`, { user_id: userId });
      if (basicInfoState && basicInfoState.current_plan) {
        navigate('/confirm-deploy-agents');
      } else {
        navigate('/pricing');
      }
    } catch (error) {
      console.error('Error updating review samples:', error);
    } finally {
      setContinueBtnLoading(false);
    }
  };

  return (
      <PageBackground>
        <Container fluid>
          {showHeader && <>
        <Card className='d-none d-lg-block'>
            <Steps
                items={[
                  {
                    title: 'Dashboard',
                    status: 'finish',
                    icon: <DashboardOutlined />,
                  },
                  {
                    title: 'Onboarding',
                    status: 'finish',
                    icon: <UserOutlined />,
                  },
                  {
                    title: 'Customize AI Personality',
                    status: 'finish',
                    icon: <RobotOutlined />,
                  },
                  {
                    title: 'Workflow & Role',
                    status: 'finish',
                    icon: <ApartmentOutlined />,
                  },
                  {
                    title: 'Data Integration & Training',
                    status: 'finish',
                    icon: <DatabaseOutlined />,
                  },
                  {
                    title: 'Connect Calendly',
                    status: 'finish',
                    icon: <CalendarOutlined />,
                  },
                  {
                    title: 'Review Samples',
                    status: 'process',
                    icon: <FileSearchOutlined />,
                  },
                  {
                    title: 'Deploy Agents',
                    status: 'wait',
                    icon: <RocketOutlined />,
                  }
                ]}
            />
          </Card>
          <Card className='p-3 d-sm-block d-none d-lg-none'>
            <Steps
                items={[
                  {
                    status: 'finish',
                    icon: <DashboardOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <UserOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <RobotOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <ApartmentOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <DatabaseOutlined />,
                  },
                  {
                    status: 'finish',
                    icon: <CalendarOutlined />,
                  },
                  {
                    status: 'process',
                    icon: <FileSearchOutlined />,
                  },
                  {
                    status: 'wait',
                    icon: <DollarOutlined />,
                  }
                ]}
            />
          </Card></>}
          <div className="d-flex  flex-column flex-md-row mt-4">
          {showHeader && <Card className="p-2 mb-3 mb-md-0 me-md-3 d-none d-md-block">
              <SideMenu currentStep={7} activePage="/review-samples" />
            </Card>}
            <div className='mb-3'>
              <Button type="primary" className='d-block d-md-none' onClick={showDrawer}>
                Navigation Menu
              </Button>
            </div>
            <Drawer title="Navigation Menu" onClose={onClose} open={open}>
                {showHeader && <SideMenu currentStep={7} activePage="/review-samples" />}
            </Drawer>
            <div className="w-100 ps-md-4">
              <Card>
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <Spinner animation="border" role="status">
                    </Spinner>
                  </div>
                ) : (
                    <>
                      <div className="email-container mb-3 ">
                        <div className="email-header">
                          <img src="https://via.placeholder.com/50" alt="Profile Image" width="50" height="50"/>
                          <div>
                            <h5>{personName}</h5>
                            {renderSocialIcons()}
                          </div>
                        </div>
                        <div className="email-content">
                          <p className='mb-2'><strong>Company Information:</strong> {companyDescription}</p>
                          <p className='text-secondary fw-bold'>THE FOLLOWING IS AN AUTO-GENERATED CONVERSATION BETWEEN YOU AND {personName}</p>
                          <div className='border rounded p-3'>
                          <p><strong>Subject:</strong> {emailSubject}</p>
                            {renderEmailContent(emailContent)}
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-4">
                        <Button variant="primary" className='continue-btn' size="lg" onClick={handleContinue} disabled={continueBtnLoading}>
                          {loading ? <Spinner animation="border" role="status">
                                                    </Spinner> : 'Continue'}
                        </Button>
                      </div>
                    </>
                )}
              </Card>
            </div>
          </div>
        </Container>
      </PageBackground>
  );
}

export default EmailDisplay;
