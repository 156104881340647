import React, { useState, useEffect } from 'react';
import { Card, Typography, Input, Button, List, Avatar, message, Row, Col, Space, Spin, Popconfirm, Tooltip } from 'antd';
import { SearchOutlined, LinkOutlined, DeleteOutlined, EditOutlined, LoadingOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Spinner } from "react-bootstrap";

const { Text, Paragraph } = Typography;

const AddLinkTab = ({parentId}) => {
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [addedLinks, setAddedLinks] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [editingLink, setEditingLink] = useState(null);
  const [editedLinkName, setEditedLinkName] = useState('');
  const [loading, setLoading] = useState(false);
  const [addingLink, setAddingLink] = useState(false);
  const [fetchingLinks, setFetchingLinks] = useState(false);
  const [getfetchingLinks, SetgetFetchingLinks] = useState(false);

  useEffect(() => {
    fetchLinks();
  }, []);

  const filteredLinks = addedLinks.filter(link =>
    link.name.toLowerCase().includes(searchText.trim().toLowerCase())
  );

  const fetchLinks = async () => {
    setLoading(true);
    setFetchingLinks(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/aichat/ai_links`,{"id":parentId});
      setAddedLinks(response.data);
    } catch (error) {
      message.error('Failed to fetch links');
    } finally {
      setLoading(false);
      setFetchingLinks(false);
    }
  };

  function isValidURL(url) {
    try {
      const regex = /^https?:\/\/[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+(\/[a-zA-Z0-9-._~:?#@!$&'()*+,;=]*)?$/;
      console.log(regex.test(url));
      return regex.test(url);
    } catch (error) {
      return false;
    }
  }

  const handleAddLink = async () => {
    if (!websiteUrl.trim()){
      message.destroy();
      message.error('Please enter a valid website URL');
      return;
    }

    if(!isValidURL(websiteUrl)){
      message.destroy();
      message.error('Please enter a valid website URL');
      return;
    }
    
    setAddingLink(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/aichat/ai_links/create`, { name: websiteUrl,parent_id:parentId });
      fetchLinks();
      setWebsiteUrl('');
      message.destroy();
      message.success('Link added to knowledge base');
    } catch (error) {
      message.destroy();
      message.error('Failed to add link');
    } finally {
      setAddingLink(false);
    }
  };

  const handleFetchLinks = async () => {
    if (!websiteUrl.trim()) {
      message.destroy();
      message.error('Please enter a valid website URL');
      return;
    }
    if(!isValidURL(websiteUrl)){
      message.destroy();
      message.error('Please enter a valid website URL');
      return;
    }

    SetgetFetchingLinks(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/aichat/fetch_all_links/`, { url: websiteUrl,parent_id:parentId});
      fetchLinks();
      setWebsiteUrl('');
      message.destroy();
      message.success('Link added to knowledge base');
    } catch (error) {
      message.destroy();
      message.error('Failed to add link');
    } finally {
      SetgetFetchingLinks(false);
    }
  };

  const handleDeleteLink = async (id) => {
    setLoading(true);
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/aichat/ai_links/${id}/delete/`);
      setAddedLinks(filteredLinks.filter(link => link._id !== id));
      message.destroy();
      message.success('Link deleted');
    } catch (error) {
      message.destroy();
      message.error('Failed to delete link');
    } finally {
      setLoading(false);
    }
  };

  const handleEditLink = async (id) => {
    if (!editedLinkName.trim()) {
      message.destroy();
      message.error('Link name cannot be empty');
      return;
    }
    if(!isValidURL(editedLinkName)){
      message.destroy();
      message.error('Please enter a valid website URL');
      return;
    }



    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/aichat/ai_links/${id}/update/`, { name: editedLinkName });
      setAddedLinks(filteredLinks.map(link => link._id === id ? response.data : link));
      setEditingLink(null);
      setEditedLinkName('');
      message.destroy();
      message.success('Link updated');
    } catch (error) {
      message.destroy();
      message.error('Failed to update link');
    } finally {
      setLoading(false);
    }
  };

  const handleCancelEdit = () => {
    setEditingLink(null);
    setEditedLinkName('');
  };

  return (
    <Row gutter={24}>
      <Col lg={7} md={12} sm={12}  xs={12}>
        <Card style={{ marginBottom: '24px' }}>
          <Text>Configure your AI chat's training data with precision by providing links to relevant pages. These designated pages will serve as valuable sources to enhance and fine-tune your AI chat's intelligence, ensuring accurate and contextually aware interactions.</Text>
          <Input
            placeholder="Enter website URL"
            value={websiteUrl}
            onChange={(e) => setWebsiteUrl(e.target.value)}
            style={{ marginTop: '16px', marginBottom: '16px' }}
          />
          <Space direction="vertical" align='center' style={{ width: '100%' }}>
            <Space>
              <Button type="primary" onClick={handleAddLink} disabled={addingLink}>
                {addingLink ? <LoadingOutlined /> : null}
                Add to knowledge base
              </Button>
            </Space>
            <Text>or</Text>
            <Space>
              <Button onClick={handleFetchLinks}>
                {getfetchingLinks ? <LoadingOutlined /> : null}
                Fetch links
              </Button>
            </Space>
            <Text type="secondary" style={{ display: 'block', marginTop: '16px' }}>
              This will crawl all the links starting with the URL (not including files on the website).
            </Text>
          </Space>
        </Card>
      </Col>
      <Col lg={17} md={12}  sm={12} xs={12}>
        <Card style={{ height: 'calc(100vh - 200px)', display: 'flex', flexDirection: 'column' }}>
          <Input
            placeholder="Search links"
            prefix={<SearchOutlined />}
            value={searchText}
            style={{ marginBottom: '16px' }}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div style={{ flexGrow: 1, overflowY: 'auto', height: '300px' }}>
              <List
                bordered
                dataSource={filteredLinks}
                renderItem={item => (
                  <List.Item
                    actions={
                      editingLink === item._id
                        ? [
                            <Button
                              icon={<SaveOutlined />}
                              onClick={() => handleEditLink(item._id)}
                              type="primary"
                              size="small"
                            >
                              Save
                            </Button>,
                            <Button
                              icon={<CloseOutlined />}
                              onClick={handleCancelEdit}
                              size="small"
                            >
                              Cancel
                            </Button>
                          ]
                        : [
                            <EditOutlined onClick={() => {
                              setEditingLink(item._id);
                              setEditedLinkName(item.name);
                            }} />,
                            <Popconfirm
                              title="Are you sure you want to delete this data!"
                              onConfirm={() => handleDeleteLink(item._id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Tooltip title="Delete Link">
                                <DeleteOutlined />
                              </Tooltip>
                            </Popconfirm>
                          ]
                    }
                  >
                    <List.Item.Meta
                      avatar={<Avatar icon={<LinkOutlined />} style={{ backgroundColor: '#1890ff' }} />}
                      title={
                        editingLink === item._id ? (
                          <Input
                            value={editedLinkName}
                            onChange={(e) => setEditedLinkName(e.target.value)}
                            onPressEnter={() => handleEditLink(item._id)}
                          />
                        ) : (
                          <>
                            <a href={item.name} target="_blank" rel="noopener noreferrer">{item.name}</a>
                            <Paragraph type="secondary" style={{ margin: 0, fontSize: '12px' }}>Updated on {new Date(item.updated_at).toLocaleString()}</Paragraph>
                          </>
                        )
                      }
                    />
                  </List.Item>
                )}
                locale={{ emptyText: 'No links added yet' }}
              />
            </div>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default AddLinkTab;