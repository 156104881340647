import React, { useState, useEffect, useRef } from 'react';
import { Modal, Table, Form, Pagination, Spinner, Alert, Button } from 'react-bootstrap';
import { FaUpload } from 'react-icons/fa';
import axios from 'axios';
import { Spin } from 'antd';
import ProgressBar from "@ramonak/react-progress-bar";
import Lottie from 'react-lottie';
import animationData from '../assets/waiting.json';

function ValidateProspectListModal({ show, onHide, ownProspectListFile, userId,onUploadComplete}) {
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const wsRef = useRef(null);
  
  const connectWebSocket = () => {
    if (wsRef.current) {
      wsRef.current.close();
    }

    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const host = process.env.REACT_APP_WS_URL || window.location.host;
    const wsUrl = `${process.env.REACT_APP_WS_URL}/ws/upload_progress/${userId}/`;
    const ws = new WebSocket(wsUrl);

    ws.onopen = () => {
      setError(null);
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);

        if (data.type === 'connection_established') {
          return;
        }

        setUploadProgress(data.progress);

        if (data.progress === 100) {
          setUploadSuccess(true);
          setTimeout(() => {
            onUploadComplete();
            onHide();
          }, 500);
        }
      } catch (error) {
        console.error('Error processing WebSocket message:', error);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      setError('WebSocket connection error. Please try again.');
    };

    ws.onclose = (event) => {
      console.log('WebSocket Disconnected:', event.code, event.reason);
      // Attempt to reconnect unless the component is unmounting
      if (show) {
        console.log('Attempting to reconnect...');
        setTimeout(connectWebSocket, 3000);
      }
    };

    wsRef.current = ws;
  };
  

  useEffect(() => {
    if (ownProspectListFile) {
      fetchCompanies();
    }
  }, [currentPage, searchTerm, ownProspectListFile]);

  useEffect(() => {
    if (show && userId) {
      connectWebSocket();
    }

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [show, userId]);

  const fetchCompanies = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const formData = new FormData();
      formData.append('prospect_list', ownProspectListFile);
      formData.append('page', currentPage);
      formData.append('search', searchTerm);
      formData.append('user_id', userId);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/agent_setup/validate_own_prospect_list`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      setCompanies(response.data.results);
      setTotalPages(response.data.total_pages);
      setHasNext(response.data.has_next);
      setHasPrevious(response.data.has_previous);
      setTotalResults(response.data.total_results);
    } catch (error) {
      console.error('Error fetching companies:', error);
      const errorMessage = error.response && error.response.data.error ? error.response.data.error : 'An error occurred while fetching companies. Please try again.';
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const getStatusStyle = (status) => {
    return {
      color: status.toLowerCase() === 'already exists' || status.toLowerCase() === 'valid' ? 'green' : 'red',
      fontWeight: 'bold'
    };
  };

  const renderPaginationItems = () => {
    const items = [];
    const maxVisiblePages = 3;
    const ellipsis = <Pagination.Ellipsis key="ellipsis" />;

    items.push(
      <Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} />,
      <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={!hasPrevious} />
    );

    if (totalPages <= maxVisiblePages) {
      for (let number = 1; number <= totalPages; number++) {
        items.push(
          <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        );
      }
    } else {
      let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
      let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

      if (endPage - startPage < maxVisiblePages - 1) {
        startPage = Math.max(1, endPage - maxVisiblePages + 1);
      }

      if (startPage > 1) {
        items.push(ellipsis);
      }

      for (let number = startPage; number <= endPage; number++) {
        items.push(
          <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        );
      }

      if (endPage < totalPages) {
        items.push(ellipsis);
      }
    }

    items.push(
      <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={!hasNext} />,
      <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    );

    return items;
  };


 const handleUpload = async () => {
  setIsUploading(true);
  setError(null);
  setUploadSuccess(false);
  setUploadProgress(0);

  try {
    const formData = new FormData();
    formData.append('file', ownProspectListFile);
    formData.append('user_id', userId);
    formData.append('collection_name', 'own_prospect_list');

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/agent_setup/upload_csv_file`, 
      formData, 
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
    );
 
  } catch (error) {
    console.error('Error uploading file:', error);
    const errorMessage = error.response && error.response.data.error 
      ? error.response.data.error 
      : 'An error occurred while uploading the file. Please try again.';
    setError(errorMessage);
    setIsUploading(false);
  } 
};

const onClose = () => {
  if (wsRef.current) {
    wsRef.current.close();
  }
  setCompanies([]);
  setTotalResults(0);
  setUploadProgress(0);
  setIsUploading(false);
  setError(null);
  onHide();
};


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header>
        <Modal.Title>Prospect List Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ position: 'relative', minHeight: '300px' }}>
        {error && (
          <Alert variant="danger">
            {error}
          </Alert>
        )}
      
        <Form.Group className="mb-3">
          {/* <Form.Control
            type="text"
            placeholder="Search by Company Domain"
            value={searchTerm}
            onChange={handleSearchChange}
          /> */}
        </Form.Group>
        <div style={{ opacity: isLoading ? 0.5 : 1 }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Person Name</th>
                <th>Person Email</th>
                <th>Company Name</th>
                <th>Country</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company, index) => (
                <tr key={index}>
                  <td>{company?.person_name}</td>
                  <td>{company?.person_email}</td>
                  <td>{company.company_name}</td>
                  <td>{company.country}</td>
                  <td style={getStatusStyle(company.status)}>{company.status}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop: '1rem'}}>
          {!isUploading &&<Pagination size="sm">{renderPaginationItems()}</Pagination>}
            <div>Total Results: {totalResults}</div>
          </div>
        </div>
        {isLoading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(255, 255, 255, 0.7)',
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            {/* <Spin tip="Loading">Loading...</Spin> */}
          </div>
        )}
         {isUploading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            background: 'rgba(255, 255, 255)',
          }}
        >
          <div className='d-flex flex-column justify-content-center align-items-center w-100'>
            <div>
              <Lottie 
                options={defaultOptions}
                height={120}
                width={120}
              />
            </div>
            <ProgressBar 
              className='pbar'
              completed={Math.round(uploadProgress)}
              bgColor="#0189ff"
              labelColor="#fff"
              animateOnRender
            />
          </div>
        </div>
      )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button 
          variant="outline-primary" 
          className="me-2" 
          style={{padding: '0.375rem 0.75rem', display:'flex', justifyContent:'center', alignItems:'center'}}
          onClick={handleUpload}
          disabled={isLoading || isUploading || error}
        >
          <FaUpload size={16} style={{marginRight: '0.5rem'}} />
          Upload
        </Button>
      </Modal.Footer>
      </Modal>      
  );
}

export default ValidateProspectListModal;